<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
})
</script>

<script lang="ts" setup>
interface props {
  modelValue: string
  error?: string
}
interface emits {
  (e: 'update:modelValue', payload: string): void
}

const props = defineProps<props>()
const emit = defineEmits<emits>()

const updateValue = (event: Event) => {
  if (event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.value)
  }
}
</script>

<template>
  <div class="input-wrapper">
    <div class="input-inner">
      <slot></slot>
      <input :value="modelValue" @input="updateValue" v-bind="$attrs" />
      <slot name="end"></slot>
    </div>
    <small v-if="error" class="error" v-text="error"></small>
  </div>
</template>

<style lang="postcss" scoped>
.input-wrapper {
  &:has(.error) {
    .input-inner {
      border-color: var(--warning-color);
    }
  }
  .input-inner {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-inline: 2.4rem;
    border-radius: var(--input-border-radius, var(--border-radius-rounded));
    border: 1px solid var(--input-border-color, var(--border-color));
    transition: border 500ms;
    &:focus-within {
      border-color: var(--input-border-focus-color, var(--secondary-color));
    }
    & input {
      appearance: none;
      border: 0;
      height: 100%;
      font-size: inherit;
      outline-style: solid;
      flex: 1;
      outline: transparent;
      color: currentColor;
      background-color: transparent;
      flex-basis: 0;
      font-weight: 400;
      height: var(--input-height, var(--input-height, 5.4rem));
      &::placeholder {
        opacity: 1;
        color: currentColor;
        font-size: inherit;
        font-weight: 400;
      }
    }
  }
  & small.error {
    color: var(--warning-color);
  }
}
</style>
